import React, { useEffect, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
// import DeleteIcon from './../../../Assets/'
import DeliveryAddressIcon from '../../../Assets/Payment/DeliveryAddress.svg';
import DeleteIcon from '../../../Assets/Payment/delete.svg';
import RemoveCartIcon from '././../../../Assets/Payment/RemoveCart.svg';
import { useSelector, useDispatch } from 'react-redux';
import { deleteSingleCard } from '../../../redux/features/Account/userCardsSlice';
import Slide from '@mui/material/Slide';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const DeleteCardDetails = ({ cardItem, authUserData }) => {
  const dispatch = useDispatch();
  const storeDataState = useSelector((state) => state.singleStores);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const deleteCard = (cardItem) => {
    const data = {
      paymethod_key: cardItem?.key,
      merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
      cid: authUserData?.id,
    };
    // console.log(storeDataState);
    dispatch(deleteSingleCard(data));
  };
  const handleClose = () => {
    setOpen(false);
  };

  //   useEffect(() => {
  //     if (
  //       orderPlaceApiResponse?.status === false &&
  //       paymentType === 'newdebitcard'
  //     ) {
  //       setOpen(true);
  //     }
  //   }, [orderPlaceApiResponse, paymentType]);

  return (
    <>
      {/* <Button className="payment-checkout-with-login" onClick={handleClickOpen}>
        Pay
      </Button> */}
      <img
        className="delete-card-icon"
        onClick={(e) => {
          //   deleteCard(cardItem);
          handleClickOpen(e);
          e.stopPropagation();
        }}
        src={DeleteIcon}
        alt="delete"
        width={'20px'}
        height={'20px'}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div-card"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img src={RemoveCartIcon} alt="No-products" />
            {/* {orderPlaceApiResponse?.status === false ? (
              <h1> {orderPlaceApiResponse?.reason}</h1>
            ) : (
              <h1> Please Select Delivery Address</h1>
            )} */}
            {/* <p>Please Select Delivery Address</p> */}
            <span>Are you sure you want to delete?</span>
          </div>

          <div className="payment-modal-button">
            <button
              onClick={(e) => {
                handleClose();
                e.stopPropagation();
              }}
            >
              Cancel
            </button>
            <button
              style={{ background: ' #FF4040', color: '#fff' }}
              onClick={(e) => {
                handleClose(e);
                deleteCard(cardItem);
                e.stopPropagation(e);
              }}
            >
              Delete
            </button>
          </div>
        </div>

        {/* <p className='delivery-amount-modal-cart-text'>Please Select Delivery Address</p> */}
      </Dialog>
    </>
  );
};

export default DeleteCardDetails;
