import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function OrderSummeryCarousel(props) {
  return (
    <>
      <Carousel data-bs-theme="dark" className='carouselIcon'>
        {props.orderSummeryData && props.orderSummeryData.map((image, index) => (
          <Carousel.Item className="carouselIconNew" key={index}>
            <img
              className="d-block w-100 carousel-Image-size"
              src={image}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}
