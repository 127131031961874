import React, { useEffect } from 'react';
import { BASE_URL_SANDBOX } from '../../Constants/Config';

export const NoDataFoundError = ({ noDataFound, setNoDataFound }) => {
  useEffect(() => {
    if (noDataFound) {
      setTimeout(() => setNoDataFound(false), 3000);
    }
  }, [noDataFound]);
  return (
    <>
      {noDataFound && (
        <div className="alert home_alert_div" role="alert">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setNoDataFound(false)}
          >
            <img
              aria-hidden="true"
              src={BASE_URL_SANDBOX + 'quickimages/home/alert_close.svg'}
              alt=""
            />
          </button>
          <h3>Sorry! We couldn't find any matches</h3>
          <p>Please check the spelling or try searching something else.</p>
        </div>
      )}
    </>
  );
};
