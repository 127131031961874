import American_Express from '../Assets/Card logos/American Express.svg';
import Carte_Blanche from '../Assets/Card logos/Carte Blanche.svg';
import diners from '../Assets/Card logos/diners.svg';
import discover from '../Assets/Card logos/discover.svg';
import Maestro from '../Assets/Card logos/Maestro.svg';
import Mastercard from '../Assets/Card logos/Mastercard.svg';
import Rupay from '../Assets/Card logos/Rupay.svg';
import unionpay from '../Assets/Card logos/unionpay.svg';
import Visa_Electron from '../Assets/Card logos/Visa Electron.svg';
import JCB from '../Assets/Card logos/JCB.svg';
import Visa from '../Assets/Card logos/Visa.svg';
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';
const usePaymentCard = () => {
  const cardLogos = [
    {
      type: 'Visa',
      src: Visa,
    },
    {
      type: 'Mastercard',
      src: Mastercard,
    },
    {
      type: 'Master',
      src: Mastercard,
    },
    {
      type: 'American Express',
      src: American_Express,
    },
    {
      type: 'AmEx',
      src: American_Express,
    },
    {
      type: 'Carte Blanche',
      src: Carte_Blanche,
    },
    {
      type: 'Diners',
      src: diners,
    },
    {
      type: 'Discover',
      src: discover,
    },
    {
      type: 'Maestro',
      src: Maestro,
    },
    {
      type: 'Rupay',
      src: Rupay,
    },
    {
      type: 'UnionPay',
      src: unionpay,
    },
    {
      type: 'Visa Electron',
      src: Visa_Electron,
    },
    {
      type: 'JCB',
      src: JCB,
    },
  ];

  const getCardLogo = (cardType) => {
    // console.log(cardType);
    const card = cardLogos.find(
      (cardLogo) => cardLogo.type === cardType.trim(),
    );
    return card ? card.src : null;
  };
  return { getCardLogo };
};

export default usePaymentCard;
