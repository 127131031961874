import React, { useContext, useEffect, useState } from 'react';
import { fetchSearchCategoryData } from '../../redux/features/SearchCategory/searchCategorySlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { Skeleton } from '@mui/material';
// import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.webp';
import SingleProduct from '../Store/Products/SingleProduct';
import { useNavigate } from 'react-router-dom';
import {
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
} from '../../Constants/Config';
import { ContextSearch } from '../../Context/SearchContext';
import { NoDataFoundError } from './NoDataFoundError';
import { Swiper, SwiperSlide } from 'swiper/react';
// import './../../Styles/SingleStore.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import SingleStore from '../Store/SingleStore/SingleStore';
const SingleCategory = () => {
  const { checkOrderMethod, noDataFound, setNoDataFound } =
    useContext(ContextSearch);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get('category');
  const [searchCategoryData, setSearchCategoryData] = useState();
  const searchCategoryDataState = useSelector((state) => state.searchCategory);

  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 2,
  };

  useEffect(() => {
    const data = {
      smartsearch: category,
    };
    if (data && data.smartsearch) {
      dispatch(fetchSearchCategoryData(data));
    }
  }, []);
  const dataformater = (incoming) => {
    try {
      incoming = JSON.parse(JSON.stringify(incoming));
      let stores = incoming.stores || [];
      let store_products = incoming.store_products || [];
      let myMap = new Map();
      for (let index = 0; index < store_products.length; index++) {
        const element = store_products[index];
        if (element && Array.isArray(element) && element.length) {
          myMap.set(element[0].merchant_id, element);
        }
      }
      let formatedData = stores.map((singleStore) => {
        if (myMap.has(singleStore.merchant_id)) {
          singleStore['products'] = myMap.get(singleStore.merchant_id);
        } else singleStore['products'] = [];
        return singleStore;
      });
      return formatedData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    if (
      !searchCategoryDataState.loading &&
      searchCategoryDataState.searchCategoryData
    ) {
      if (!searchCategoryDataState.searchCategoryData.stores) {
        // Navigate(-1)
      }
      if (
        Object.keys(searchCategoryDataState.searchCategoryData).length &&
        searchCategoryDataState.searchCategoryData.stores
      ) {
        let value = dataformater(searchCategoryDataState.searchCategoryData);
        setSearchCategoryData(value);
      }
    }
  }, [searchCategoryDataState && searchCategoryDataState.loading]);

  return (
    <>
      {/* Error Section */}
      <NoDataFoundError
        noDataFound={noDataFound}
        setNoDataFound={setNoDataFound}
      />
      {searchCategoryData &&
        searchCategoryData.length >= 1 &&
        searchCategoryData.map((item, index) =>
          item.products.length >= 1 ? (
            <div key={index} className="single-store-sproductListing-section">
              <section>
                <div className="search-category-page-store-detail">
                  <div className="search-category-store-image-div">
                    <img
                      src={
                        item.img
                          ? // ? `${BASE_URL_SANDBOX}upload/${item.img}`
                            `${PRODUCT_IMAGES_BASE_URL}upload/${item.img}`
                          : ProductDefaultImage
                      }
                      alt={item.name}
                    />
                  </div>
                  <div className="search-category-page-store-description">
                    <span>{item.name}</span>
                    <p>
                      {item.a_address_line_1 +
                        ' ' +
                        item.a_address_line_2 +
                        ' ' +
                        item.a_city +
                        ' ' +
                        item.a_state +
                        ',' +
                        item.a_zip}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="scroller-of-product"></div>
                  <Link
                    to={`/merchant/${
                      item.merchant_id
                    }?orderMethod=${checkOrderMethod(item)}`}
                    className="search-category-visit-store-btn d-flex flex-nowrap"
                  >
                    Visit Store
                  </Link>
                </div>
              </section>
              <div>
                <Swiper
                  // slidesPerView={2}
                  centeredSlides={false}
                  spaceBetween={20}
                  grabCursor={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                    534: {
                      slidesPerView: 2.3,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    930: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },
                    1500: {
                      slidesPerView: 6,
                      spaceBetween: 30,
                    },
                    1700: {
                      slidesPerView: 7,
                      spaceBetween: 30,
                    },
                  }}
                  modules={[Pagination]}
                  className="media-scroller-upper  mySwiper-singleProductListing"
                >
                  {item.products.map((singleProduct, key) => (
                    <SwiperSlide
                      key={singleProduct.id}
                      className="catagories-swiper-SingleProductListing"
                    >
                      <SingleProduct
                        product={singleProduct}
                        categoryId={singleProduct.cotegory}
                        Wishlist={false}
                        merchantId={singleProduct.merchant_id}
                        item={item}
                        hideVariantAndWishlist={true}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            ''
          ),
        )}
    </>
  );
};

export default SingleCategory;
