import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, FreeMode } from 'swiper/modules';
import SingleProduct from '../SingleProduct';
import Slider from 'react-slick';

const HighestSellingProducts = memo(
  ({ highestSellingProducts, isMobile, merchantId, settings }) => {
    return (
      <>
        {highestSellingProducts && highestSellingProducts.length > 0 && (
          <div className="quickvee-highest-selling-product-section">
            <span>Highest Selling Products</span>
            {isMobile ? (
              <Swiper
                slidesPerView={2}
                spaceBetween={8}
                // initialSlide={1}
                // centeredSlides={false}
                modules={[FreeMode, Virtual]}
                freeMode={true}
                scrollbar={{ draggable: true, dragSize: 24 }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  534: {
                    slidesPerView: 2.3,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  769: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                className="media-scroller-upper"
              >
                <div className="media-scroller">
                  {highestSellingProducts.map((product) => (
                    <SwiperSlide className="media-element">
                      <SingleProduct
                        key={product.id}
                        product={product}
                        categoryId={product.cotegory}
                        merchantId={merchantId}
                      />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            ) : (
              <Slider {...settings}>
                {highestSellingProducts.map((product) => (
                  <SingleProduct
                    key={product.id}
                    product={product}
                    categoryId={product.cotegory}
                    merchantId={merchantId}
                  />
                ))}
              </Slider>
            )}
          </div>
        )}
      </>
    );
  },
);

export default HighestSellingProducts;
