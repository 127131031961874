import React, { useState, useEffect, useRef } from 'react';
import SingleProduct from './SingleProduct';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@mui/material';
import EmptyStoreDetails from '../../../Assets/Store/EmptyStoreDetails.svg';
import useMediaQueryMaterial from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/virtual';
import { Virtual, FreeMode } from 'swiper/modules';
const SingleProductListing = ({ orderMethod, merchantId }) => {
  const [singleStoreData, setSingleStoreData] = useState();
  const isMobile = useMediaQueryMaterial('(max-width:768px)');

  const SingleStoresDataState = useSelector((state) => state.singleStores);

  const xxlscreen = useMediaQuery({ query: '(min-width: 1920px)' });
  const xlscreen = useMediaQuery({ query: '(min-width: 1700px)' });
  const lgcreen = useMediaQuery({ query: '(min-width: 1500px)' });
  const smcreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const xscreen = useMediaQuery({ query: '(min-width: 854px)' });
  const xxscreen = useMediaQuery({ query: '(min-width: 0px)' });
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      // Accessing the width of the div
      const divWidth = divRef.current.offsetWidth;
      setWidth(divWidth);
    }
  }, [singleStoreData]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    // slidesToShow: xxlscreen ? 7 : xlscreen ? 5 : xscreen ? 5 : sscreen ? 5 : 2,
    slidesToShow: xxlscreen
      ? 7
      : xlscreen
      ? 6
      : lgcreen
      ? 5
      : smcreen
      ? 5
      : xscreen
      ? 3
      : 2,
    slidesToScroll: 2,
  };

  const showViewAllButton = (productLength) => {
    const screenSizesSlides = {
      xxlscreen: 6,
      xlscreen: 5,
      lgcreen: 4,
      smcreen: 3,
      xscreen: 3,
      xxscreen: 1,
      // min767: 2,
      // min375: 1,
    };

    const allScreens = {
      xxlscreen,
      xlscreen,
      lgcreen,
      smcreen,
      xscreen,
      xxscreen: 1,
      // min767,
      // min375,
    };

    // Filter out the keys with a value of true
    const trueScreens = Object.keys(allScreens).filter(
      (key) => allScreens[key],
    );

    // Find the maximum screen size among the true screens
    const highestTrueScreen = trueScreens.reduce((maxScreen, currentScreen) => {
      // Compare screen sizes without 'min' prefix
      return parseInt(currentScreen.substring(3)) >
        parseInt(maxScreen.substring(3))
        ? currentScreen
        : maxScreen;
    }, trueScreens[0]);

    return productLength > screenSizesSlides[highestTrueScreen];
  };

  const singleStoreProductDataState = useSelector(
    (state) => state.storeProductData,
  );

  const dataformater = (incoming) => {
    try {
      incoming = JSON.parse(JSON.stringify(incoming));
      let categores = incoming.all_collection || [];
      let categores_products = incoming.all_product || [];
      let formatedData = {
        categores,
        categores_products,
      };
      const transformdata = formatedData.categores.map((cat) => {
        const collection_id = cat.collection_id;
        const collection_show_status = cat.cat_show_status;
        const collection_title = cat.collection_title;
        const collection_merchant_id = cat.collection_merchant_id;
        const collection_product =
          formatedData.categores_products[collection_id];
        return {
          collection_id,
          collection_show_status,
          collection_title,
          collection_merchant_id,
          collection_product,
        };
      });

      return transformdata;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    if (
      !singleStoreProductDataState.loading &&
      singleStoreProductDataState.storeProductData
    ) {
      // for removing categories whose cat_show_status is not 0 or 1
      if (
        !SingleStoresDataState.loading &&
        SingleStoresDataState?.storesData?.store_data &&
        SingleStoresDataState?.storesData?.categories
      ) {
        const removedCategories =
          SingleStoresDataState?.storesData?.categories?.filter(
            (category) =>
              category?.cat_show_status !== '0' &&
              category?.cat_show_status !== '1',
          );

        // console.log(
        //   'removedCategories from main store products: ',
        //   removedCategories,
        // );

        if (removedCategories && removedCategories.length > 0) {
          let value = dataformater(
            singleStoreProductDataState.storeProductData,
          );

          const data = value?.filter((item) => {
            const found = removedCategories.find(
              (category) => category.id === item.collection_id,
            );

            return !found;
          });

          // console.log('old data: ', value);
          // console.log('new data: ', data);

          setSingleStoreData(data);
        } else {
          let value = dataformater(
            singleStoreProductDataState.storeProductData,
          );
          setSingleStoreData(value);
        }
      }
    } else {
      // showcasing empty details page
    }
  }, [
    singleStoreProductDataState,
    singleStoreProductDataState.loading,
    SingleStoresDataState,
  ]);

  return (
    <>
      {singleStoreProductDataState.loading ? (
        <div
          className={`single-store-single-category-product-listing ${
            singleStoreProductDataState.loading ? 'loading' : ''
          }  `}
        >
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />{' '}
          <Skeleton
            variant="rounded"
            width={211}
            height={290}
            style={{ margin: '10px' }}
          />
        </div>
      ) : singleStoreData?.length > 0 ? (
        singleStoreData?.map((store, index) =>
          store?.collection_product?.length > 0 ? (
            <div key={index} className="single-store-sproductListing-section">
              <section ref={divRef}>
                <span>
                  {/* <span className='Store_Collection_Title'>{store.collection_title.slice(0,25)}...</span> */}
                  {store.collection_title.length > 25 ? (
                    <>{store.collection_title.slice(0, 25)}...</>
                  ) : (
                    <>{store.collection_title}</>
                  )}
                  {/* for ProductQuantity */}
                  {/* {store &&
                  store.collection_product &&
                  store.collection_product.length
                    ? ` (` + store.collection_product.length + `)`
                    : ''} */}
                </span>
                <div>
                  <div className="scroller-of-product"></div>
                  {showViewAllButton(
                    store?.collection_product?.length ?? 0,
                  ) && (
                    <Link
                      to={`/merchant/${store.collection_merchant_id}?category=${store.collection_title}&orderMethod=${orderMethod}&categoryid=${store.collection_id}`}
                    >
                      View All
                    </Link>
                  )}
                </div>
              </section>
              <div className="swpier_main_div_SingleProductListing">
                {isMobile ? (
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={8}
                    // initialSlide={1}
                    // centeredSlides={false}
                    modules={[FreeMode, Virtual]}
                    freeMode={true}
                    scrollbar={{ draggable: true, dragSize: 24 }}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      // when window width is >= 480px
                      480: {
                        slidesPerView: 2.5,
                        spaceBetween: 20,
                      },
                      534: {
                        slidesPerView: 2.3,
                        spaceBetween: 20,
                      },
                      600: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      769: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    className="media-scroller-upper swpier_main_div_SingleProductListing_inner_div"
                  >
                    <div className="media-scroller">
                      {Array.isArray(store.collection_product) &&
                        store.collection_product.map((product, index) => (
                          <SwiperSlide
                            className="media-element"
                            key={product.id}
                          >
                            <SingleProduct
                              key={product.id}
                              product={product}
                              categoryId={store.collection_id}
                              Wishlist={false}
                              merchantId={product.merchant_id}
                            />
                          </SwiperSlide>
                        ))}
                    </div>
                  </Swiper>
                ) : (
                  <Slider {...settings}>
                    {Array.isArray(store.collection_product) &&
                      store.collection_product.map((product, index) => (
                        <div key={product.id}>
                          <SingleProduct
                            key={product.id}
                            product={product}
                            categoryId={store.collection_id}
                            Wishlist={false}
                            merchantId={product.merchant_id}
                            isMobile={isMobile}
                          />
                        </div>
                      ))}
                  </Slider>
                )}
              </div>
            </div>
          ) : (
            ''
          ),
        )
      ) : (
        <div className="single-store-empty-wishlist">
          <div className="empty-wishlist-content">
            <img src={EmptyStoreDetails} alt="No-products" />
            <h1 className="m-0">STAY TUNED!</h1>
            <p className="m-0">NEW PRODUCTS ARE ON THE WAY.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleProductListing;
