
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, TextField } from "@mui/material";
import EditIcon from "../../../Assets/Payment/Edit.svg";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditPaymentCardDetail = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);

    }


    return (
        <>
            <div>
                <span className="modal-main-button" onClick={handleOpen}>
                    <img src={EditIcon} alt="edit-card-info" style={{ cursor: "pointer" }} />
                </span>




                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="basic-modal">
                        <div id="modal-modal-title">
                            <span>Card Info</span>
                            <CloseIcon onClick={handleClose} className="modal-close-icon" />
                        </div>

                        <form id="modal-modal-description" className="modal-forms">
                            <FormControl>
                                <div className="modal-form-single-item">
                                    <TextField
                                        label="Card Number"
                                        className="modal-form-single-input"
                                        id="outlined-size-small"
                                        variant="filled"
                                        size="small"
                                        autoComplete="off"
                                        name="cardnumber"
                                    // onChange={DeliveryAddressInput}
                                    // value={values.phone}
                                    />
                                    {/* <span className="input-error" >{values.errors.phone}</span> */}
                                </div>
                            </FormControl>
                            <div className="delivery-address-update-form" style={{ margin: "5px 0" }}>
                                <div >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DemoContainer components={['DatePicker']}   >

                                            <DatePicker format="LL" label="Expiry Date" slotProps={{ textField: { variant: 'filled' } }} className="modal-form-single-input " name="expiredate" />

                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {/* <span className="input-error">
                    {values.errors.expiredate}
                  </span> */}
                                </div>
                                <FormControl style={{ marginTop: "4px" }}>
                                    <div className="modal-form-single-item">
                                        <TextField
                                            label="CVV"
                                            className="modal-form-single-input"
                                            id="outlined-size-small"
                                            variant="filled"
                                            size="small"
                                            autoComplete="off"
                                            name="cvv"
                                        // onChange={DeliveryAddressInput}
                                        // value={values.firstname}
                                        />
                                        {/* <span className="input-error">
                                            {values.errors.firstname}
                                        </span> */}
                                    </div>
                                </FormControl>

                            </div>

                            <div className="modal-footer">
                                <Button className="modal-cancle-btn" onClick={handleClose}>Cancel</Button>
                                <Button className="modal-save-btn" >Save</Button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default EditPaymentCardDetail