import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_SINGLE_STORE_PRODUCT_DATA,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  storeProductData: {},
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchSingleStoreProductData = createAsyncThunk(
 
  'storeProductData/fetchSingleStoreProductData',
  async (data) => {
    
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_SINGLE_STORE_PRODUCT_DATA,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      if (response.data.status === 200) {
        return response.data.result;
      } else throw new Error(`Server error`);
    } catch (error) {
      new Error(error.message);
    }
  },
);

const singleStoreProductDataSlice = createSlice({
  name: 'storeProductData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSingleStoreProductData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingleStoreProductData.fulfilled, (state, action) => {
      state.loading = false;
      state.storeProductData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchSingleStoreProductData.rejected, (state, action) => {
      state.loading = false;
      state.storeProductData = {};
      state.error = action.error.message;
    });
  },
});

export default singleStoreProductDataSlice.reducer;
