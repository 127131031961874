import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_ALL_CART_PRODUCT,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  cartProductDetails: [],
  error: '',
};

export const fetchAllCartProductDetails = createAsyncThunk(
  'cartProducts/fetchAllCartProductDetails',
  async (data) => {
    try {
      if (data) {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_ALL_CART_PRODUCT,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.status === 200) {
          return response.data;
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log('error: ', error);
      // throw new Error('An error occurred');
    }
  },
);

const cartProductDetailsSlice = createSlice({
  name: 'cartProducts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCartProductDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCartProductDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.cartProductDetails = action.payload ?? [];
      state.error = '';
    });
    builder.addCase(fetchAllCartProductDetails.rejected, (state, action) => {
      state.loading = false;
      state.cartProductDetails = [];
      state.error = action.error.message;
    });
  },
});

export default cartProductDetailsSlice.reducer;
