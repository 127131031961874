import React from 'react';

import '../../Styles/footerpolicy.css';
import AboutImages from '../../Assets/Store/shop.png';

const AboutDetails = () => {
  return (
    <>
      <div
        className="home-page"
        style={{ width: '100%', minHeight: 'unset !important' }}
      >
        <div className="vectorbanner">
          <div className="qvrow">
            <div className="col-qv-12">
              <div className="vectorbannerinnerR">
                <h3>About Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about details */}

      <div className="home-page-all-sections">
        <div className="pricacy_div">
          <p>
            We are payment processing veterans and understand the complexity and
            challenges smoke shops face in selling their products online.
            Quickvee provides online ordering for your smoke shop without the
            headaches. Our platform allows you to list your products so
            customers can browse your inventory and place orders Online. Orders
            automatically print in your store for pickup and/or delivery.
          </p>
        </div>

        {/* Why Partner with Quickvee? details block */}

        <div className="pricacy_div">
          <h1>Why Partner with Quickvee?</h1>

          <ul>
            <li>
              Online Ordering - Sell Glass, Bongs, Kratom, Delta 8, CBD, and
              Smoking Accessories Online.
            </li>
            <li>Get your own custom branded website</li>
            <li>
              Bigger Orders! - Online orders are larger compared to in-store
              orders.
            </li>
            <li>User-friendly Interface</li>
            <li>
              Auto Order Print: Orders will automatically print for pickup or
              delivery.
            </li>
            <li>
              Delivery Orders (Optional): flat delivery, per mile, delivery
              radius &amp; delivery zones
            </li>
            <li>
              Pick-Up Orders: Customers place orders online (Cash or Credit) and
              pick-up from store
            </li>
            <li>Dispatch Center: Manage delivery orders and payouts.</li>
            <li>
              Order Status Updates: Let customers know when the order is ready
              for pickup with a click of a button.
            </li>
            <li>
              Coupons and Promo Codes: Create coupons &amp; promo codes to boost
              your sales.
            </li>
            <li>Reporting: Robust reporting to keep track of your sales.</li>
            <li>Payments: Cash, Debit Cards, and all Major Credit Cards.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AboutDetails;
