import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Input, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const SearchLocation = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        <Button
          className="modal-main-button search-location-button"
          onClick={handleOpen}
        >
          <FmdGoodIcon style={{ color: '#000' }} />
          <span style={{ color: '#000', marginLeft: '5px' }}>
            {' '}
            Search Location
          </span>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal" style={{ top: '25%' }}>
            <div id="modal-modal-title">
              <span style={{ fontSize: '18px', marginLeft: '150px' }}>
                Choose Address
              </span>
              <CloseIcon onClick={handleClose} className="modal-close-icon" />
            </div>

            <div
              id="modal-modal-description"
              className="modal-forms"
              style={{ padding: '0px' }}
            >
              <div className="home-top-bar-search">
                <Input
                  type="search"
                  className="home-top-bar-search-input"
                  placeholder="Search Location"
                />
                <SearchIcon className="home-top-bar-search-icon" />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SearchLocation;
