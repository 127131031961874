// import React from 'react';
// import SingleStoreWishlist from '../Wishlist/SingleStoreWishlist';
// import SingleStoreAllInformationPage from './SingleStoreAllInformationPage';
// import SingleProductPage from '../Products/SingleProductPage';
// // import { useDispatch } from 'react-redux';
// // import { fetchSingleStoreData } from '../../../redux/features/SingleStore/singleStoreSlice';

// const SingleStore = ({ visible, toggleDrawer }) => {
//   return (
//     <>
//       <div className="quickvee-single-store-main-area-detail-page">
//         {visible === 'wishlist' && <SingleStoreWishlist visible={visible} />}
//         {visible === 'product' && (
//           <SingleProductPage visible={visible} toggleDrawer={toggleDrawer} />
//         )}
//         {visible === 'merchant' && (
//           <SingleStoreAllInformationPage visible={visible} />
//         )}
//         {/* {visible === 'StoreCreditZone' && <StoreCreditZone visible={visible} />}
//         {visible === 'LoyaltyPointZone' && <LoyaltiPointZone visible={visible} />} */}
//       </div>
//     </>
//   );
// };

// export default SingleStore;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SingleStoreWishlist from '../Wishlist/SingleStoreWishlist';
import SingleStoreAllInformationPage from './SingleStoreAllInformationPage';
import { Skeleton, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import SingleProductPage from '../Products/SingleProductPage';
import {
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_BASE_URL,
} from '../../../Constants/Config';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
import LocationIcon from '../../../Assets/Store/location.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import ViewFullHours from './ViewFullHours';
// import { useDispatch } from 'react-redux';
// import { fetchSingleStoreData } from '../../../redux/features/SingleStore/singleStoreSlice';
import SearchBar from './SearchBar';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Virtual } from 'swiper/modules';
import CouponInfo from './../../../Assets/Store/CouponInfo.svg';
import { useMediaQuery } from '@mui/material';

// import './../../../Styles/SingleStore.css'
import { TabScrollButton, Tab, Tabs } from '@mui/material';
import Footer from '../../MainHome/Footer';
const SingleStore = ({
  visible,
  toggleDrawer,
  merchantId,
  orderType,
  setOrderType,
  singleProductDataPushUp,
  isOvelay,
  handleOverlayClickClose,
  handleNavClick,
}) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentUrl = location.pathname;

  const categorysId = searchParams.get('categoryid');
  const [singleStoreData, setSingleStoreData] = useState(null);
  const [clickedButtons, setClickedButtons] = useState([]);
  const [isHideStore, setHideStore] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const SingleStoresDataState = useSelector((state) => state.singleStores);
  useEffect(() => {
    if (
      !SingleStoresDataState.loading &&
      SingleStoresDataState?.storesData?.store_data &&
      SingleStoresDataState.storesData.categories
    ) {
      setSingleStoreData(SingleStoresDataState.storesData.store_data);
      setCategories(SingleStoresDataState.storesData.categories);
    }
  }, [SingleStoresDataState, SingleStoresDataState.loading]);

  const handleOrderStatus = (ordertype, e) => {
    e.preventDefault();
    setOrderType(ordertype);
    const params = new URLSearchParams(location.search);
    params.set('orderMethod', ordertype);
    Navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
    localStorage.removeItem(`cart_${merchantId}`);
    dispatch(setCartData([]));
  };

  useEffect(() => {
    if (currentUrl.split('/')[1] === 'product') {
      setHideStore(true);
    } else if (currentUrl.split('/')[1] === 'wishlist') {
      setHideStore(true);
    } else {
      setHideStore(false);
    }
  });
  return (
    <>
      {isOvelay ? (
        <div
          onClick={() => {
            handleOverlayClickClose();
            handleNavClick();
          }}
          className="overlay"
        ></div>
      ) : (
        ''
      )}
      <Grid
        container
        justifyContent={'start'}
        style={{}}
        className="quickvee-single-store-main-area-detail-page"
      >
        <Grid style={{ minHeight: 'calc(100vh - 137px)' }} item md={12} xl={12}>
          {isHideStore ? (
            ''
          ) : (
            <div>
              {isMobile ? (
                <div className="songleStoreSearchBar">
                  <SearchBar merchantId={merchantId} orderType={orderType} />
                </div>
              ) : (
                ''
              )}

              <div>
                {/* <SearchBar
                allproducts={singleStoreProductData && singleStoreProductData.all_product}
                allcategories={singleStoreProductData && singleStoreProductData.all_collection}
                merchantId={merchantId}
                orderType={orderType}
              /> */}
              </div>

              {!SingleStoresDataState.loading && SingleStoresDataState.error ? (
                <h1> Error : {SingleStoresDataState.error} </h1>
              ) : null}
              <div className={`quickvee-single-store-logo-singleStore `}>
                {SingleStoresDataState.loading ? (
                  <Skeleton
                    variant="circular"
                    width={75}
                    height={75}
                    style={{ borderRadius: '50%' }}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                      to={`/merchant/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
                    >
                      <img
                        src={
                          singleStoreData?.ses_m_img
                            ? // ? `${BASE_URL_SANDBOX}upload/${singleStoreData?.ses_m_img}`
                              `${PRODUCT_IMAGES_BASE_URL}upload/${singleStoreData?.ses_m_img}`
                            : ProductDefaultImage
                        }
                        alt="store"
                        loading="lazy"
                        onError={(e) => {
                          e.target.src = ProductDefaultImage;
                        }}
                      />
                    </Link>

                    <div
                      className={`quickvee-left-menu-single-store-detail-sticky `}
                    >
                      {singleStoreData ? (
                        <div
                          className={
                            'quickvee-single-store-content-singleStore'
                          }
                        >
                          <span>
                            {singleStoreData?.store_name
                              ? singleStoreData.store_name
                              : ''}
                            {/* <img
                      src={InfoOutlinedIcon}
                      alt="quickvee-store-info"
                      style={{ marginLeft: '5px' }}
                      loading="lazy"
                    /> */}
                            {SingleStoresDataState.loading ? (
                              ''
                            ) : singleStoreData?.store_oc_status === 'open' ? (
                              <span
                                className="store-open-close-status"
                                style={{ background: '#14AE2D' }}
                              >
                                Open
                              </span>
                            ) : (
                              <span
                                className="store-open-close-status"
                                style={{ background: '#E55252' }}
                              >
                                Closed
                              </span>
                            )}
                          </span>
                          <p className={` `}>
                            <img
                              src={LocationIcon}
                              alt="location"
                              loading="lazy"
                            />
                            {singleStoreData && singleStoreData.add
                              ? singleStoreData.add + ', '
                              : ''}

                            <>
                              {singleStoreData?.city
                                ? singleStoreData.city + ', '
                                : ''}
                              {singleStoreData?.state
                                ? singleStoreData.state + ', '
                                : ''}
                              {singleStoreData?.zip
                                ? singleStoreData.zip + '. '
                                : ''}
                            </>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                      <ViewFullHours
                        SingleStoresDataState={SingleStoresDataState}
                        orderMethod={orderType}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                )}
                {/* <div className='quickvee-single-store-pickup-delivery'> */}
                {/* <div className="quickvee-single-store-pickup-delivery">
                  {singleStoreData?.is_deliver === 'Yes' ? (
                    <Button
                      disabled={orderType !== 'pickup' ? true : false}
                      className={
                        orderType === 'delivery' ? 'active-delivery-button' : ''
                      }
                      onClick={(e) => handleOrderStatus('delivery', e)}
                    >
                      Delivery
                    </Button>
                  ) : (
                    ''
                  )}
                  {singleStoreData?.is_pickup === 'Yes' ? (
                    <Button
                      disabled={orderType === 'pickup' ? true : false}
                      className={
                        orderType === 'pickup' ? 'active-delivery-button' : ''
                      }
                      onClick={(e) => handleOrderStatus('pickup', e)}
                    >
                      Pickup
                    </Button>
                  ) : (
                    ''
                  )}
                </div> */}
                {/* </div> */}
              </div>

              {/* {isMobile ? (
              <Swiper
                slidesPerView={6}
                centeredSlides={false}
                // spaceBetween={30}
                modules={[Virtual]}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 3.5,
                  },
                  // when window width is >= 480px
                  360: {
                    slidesPerView: 3.3,
                  },
                  480: {
                    slidesPerView: 4.5,
                  },
                  551: {
                    slidesPerView: 5.7,
                  },
                  674: {
                    slidesPerView: 6.3,
                  },

                  // when window width is >= 768px
                  768: {
                    slidesPerView: 7.3,
                    spaceBetween: 30,
                  },
                }}
                grabCursor={true}
                className="mySwiper-catagories-swiper-single-store"
                // scrollButtons="auto"
                // aria-label="scrollable auto tabs example"
              >
                <SwiperSlide className="catagories-swiper-single-store">
                  <Link
                    to={`/merchant/${singleStoreData?.merchant_id}?orderMethod=${orderType}`}
                  >
                    <Button
                      className="Swiper-btn"
                      style={{ textTransform: 'capitalize' }}
                      variant="outlined"
                      disabled={
                        categorysId !== undefined && categorysId !== null
                      }
                    >
                      All
                    </Button>
                  </Link>
                </SwiperSlide>
                {categories &&
                  categories.map((category, index) => (
                    <>
                      <SwiperSlide
                        key={index}
                        className="catagories-swiper-single-store"
                      >
                        <Link
                          key={category.id}
                          to={`/merchant/${category.merchant_id}?category=${category.title}&orderMethod=${orderType}&categoryid=${category.id}`}
                        >
                          <Button
                            className="Swiper-btn"
                            style={{ textTransform: 'capitalize' }}
                            variant="outlined"
                            disabled={categorysId !== category.id}
                          >
                            {' '}
                            {category.title}
                          </Button>
                        </Link>
                      </SwiperSlide>
                    </>
                  ))}
              </Swiper>
            ) : (
              ''
            )} */}
            </div>
          )}

          {visible === 'wishlist' && <SingleStoreWishlist visible={visible} />}
          {visible === 'product' && (
            <SingleProductPage visible={visible} toggleDrawer={toggleDrawer} />
          )}
          {visible === 'merchant' && (
            <SingleStoreAllInformationPage visible={visible} />
          )}
          {/* {visible === 'StoreCreditZone' && <StoreCreditZone visible={visible} />}
        {visible === 'LoyaltyPointZone' && <LoyaltiPointZone visible={visible} />} */}
        </Grid>
        <div className="common-footer-div profile-footer">
          <Footer />
        </div>
      </Grid>
    </>
  );
};

export default SingleStore;
