import React, { useEffect, useState, useRef } from 'react';
import { Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedProducts } from '../../../redux/features/SingleStore/searchProducts';
import useDebounce from '../../../hooks/useDebounce';

const SearchBar = ({ merchantId, orderType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterProducts, setFilterProducts] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchBoxRef = useRef(null);
  const resultContainer = useRef(null);
  const debouncedValue = useDebounce(searchTerm);
  const searchedProducts = useSelector(
    (state) => state.searchProducts.searchedProducts,
  );

  const divScroll = () => {
    const mydiv = document.getElementById('search-data-box');
    mydiv?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    divScroll();
  }, [showSearchResults]);

  // setting Products in Merchant Searchbar
  useEffect(() => {
    if (searchedProducts && searchedProducts.length >= 1) {
      setFilterProducts(searchedProducts);
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [searchedProducts]);

  useEffect(() => {
    const data = {
      keyword: debouncedValue,
      merchantId,
      orderType,
    };
    dispatch(getSearchedProducts(data));
  }, [debouncedValue]);

  useEffect(() => {
    if (!resultContainer.current) return;
    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  // Add a click event listener to close the search results box when clicking outside
  useEffect(() => {
    // console.log(merchantId)
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
        setSearchTerm('');
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const resetSearchComplete = () => {
    setSearchTerm('');
    setShowSearchResults(false);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    // move down
    if (key === 'ArrowDown')
      nextIndexCount = (focusedIndex + 1) % filterProducts.length;

    // move up
    if (key === 'ArrowUp')
      nextIndexCount =
        (focusedIndex + filterProducts.length - 1) % filterProducts.length;

    // hide search results
    if (key === 'Escape') {
      resetSearchComplete();
    }

    // select the current item
    if (key === 'Enter') {
      e.preventDefault();
      handleSelection(focusedIndex);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleSelection = (selectedIndex) => {
    const selectedItem = filterProducts[selectedIndex];
    if (!selectedItem) return resetSearchComplete();

    if (selectedItem.product_id) {
      const to = `/product/${merchantId}?orderMethod=${
        orderType ? orderType : 'pickup'
      }&categoryid=${selectedItem && selectedItem.category_id}&product=${
        selectedItem && selectedItem.product_id
      }`;
      navigate(to);
    }

    resetSearchComplete();
  };

  const isBool = (index) =>
    filterProducts && filterProducts.length - 1 === index;

  return (
    <>
      <div
        tabIndex={1}
        onBlur={resetSearchComplete}
        onKeyDown={handleKeyDown}
        className="relative"
        ref={searchBoxRef}
      >
        <div className="order-top-bar-search">
          <Input
            type="search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="order-top-bar-search-input"
            value={searchTerm}
            placeholder="Search Product"
          />
          <SearchIcon className="order-top-bar-search-icon" />
        </div>

        <div
          className="search-data-box"
          id="search-data-box"
          style={{ display: showSearchResults ? 'block' : 'none' }}
        >
          {filterProducts && filterProducts.length >= 1 ? (
            filterProducts.map((product, index) => (
              <Link
                key={index}
                ref={index === focusedIndex ? resultContainer : null}
                onMouseDown={() => handleSelection(index)}
                style={{
                  backgroundColor: index === focusedIndex ? '#f6f7f8' : '',
                  borderBottom: isBool(index) ? '0px' : '1px solid #E1E1E1',
                  // padding: '0px 10px',
                }}
                className="search-bar-link"
                to={`/product/${merchantId}?orderMethod=${
                  orderType ? orderType : 'pickup'
                }&categoryid=${product && product.category_id}&product=${
                  product && product.product_id
                }`}
              >
                {' '}
                {product.value}
              </Link>
            ))
          ) : (
            <p className="search-bar-link">No Product found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
